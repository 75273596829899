import 'regenerator-runtime/runtime'

import {FrameCalculatorModal} from "./FrameCalulatorModal";


const ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

ready(async () => {
    let $button = document.querySelector('[data-cube-sizing]')
    if($button) {
        const frameCalculatorModal = new FrameCalculatorModal($button);
        await frameCalculatorModal.init();
    }
});
