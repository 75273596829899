import jss from 'jss'
import preset from 'jss-preset-default'
import {IModalCalculatorModal} from "./types";
import {mainStyles} from "./styles";
import {IFrameComponent, iframeResizer} from 'iframe-resizer';
import axios from "axios";

class FrameCalculatorModal implements IModalCalculatorModal {
    API_URL = 'https://bikesizing.cube.eu/api';
    productId: string;
    $buttonElement: Element;
    productTitle: string;
    styles: any;
    $modalBox: any;
    initialised = false;
    modalState: 'opened' | 'closed';
    iframeResizer: IFrameComponent[];
    language = null;
    color = null;
    cubeMode=null;

    constructor(buttonElement: Element) {
        this.$buttonElement = buttonElement;
        this.productId = this.$buttonElement.getAttribute("data-cube-sizing-product").trim();
        this.language = this.$buttonElement.getAttribute("data-cube-sizing-language");
        this.productTitle = this.$buttonElement.getAttribute('data-cube-sizing-title');
        this.color = this.$buttonElement.getAttribute("data-cube-sizing-color");
        this.cubeMode = this.$buttonElement.hasAttribute("data-cube-sizing-cube-mode");
        this.modalState = 'closed';
    }

    /***
     * Initialisierung der Libary
     * @public
     */
    public async init() {
        const IS_VALID = await this.validateProduct();
        if (IS_VALID) {
            this.setupStyles();
            this.resizeIframe();
            this.setupEvents();
        } else {
            this.$buttonElement.remove();
        }
    }

    /**
     *
     * @private
     */
    private async validateProduct() {
        try {
            const PRODUCT_EXIST = await axios.get(`https://bikesizing.cube.eu/api/custom/verify?product_id=${this.productId}`);
            if (PRODUCT_EXIST.data.data.length > 0) {
                this.productId = PRODUCT_EXIST.data.data[0];
                return true;
            } else {
                return false;
            }

        } catch {
            return false;
        }

    }

    /***
     * Initialisierung der Events
     * @private
     */
    private setupEvents() {
        this.$buttonElement.addEventListener("click", (e) => {
            e.preventDefault();
            if (this.initialised) {
                this.showModalBox();
            } else {
                this.setupModalBox();
                this.initialised = true;
                this.showModalBox();
            }
        });
    }

    /**
     * resize Funktion für die neue Größenanpassung des Iframes and die Modalbox
     * @private
     */
    private resizeIframe() {
        this.iframeResizer = iframeResizer({
            log: false,
            interval: 200,
            checkOrigin: false
        }, '[data-cube-sizing-iframe]');
    }

    /**
     * Generierung der Modalbox
     * @private
     */
    private setupModalBox() {
        this.$modalBox = document.createElement("div");
        this.$modalBox.className = this.styles.modalBoxWrapper;
        this.$modalBox.setAttribute('data-cube-sizing-wrapper', null);
        this.$modalBox.setAttribute('role', 'dialog');
        this.$modalBox.innerHTML = `
            <div class="${this.styles.modalBox}" >
                <div class="${this.styles.closeIconWrapper}">
                    <button class="${this.styles.closeIcon}" data-cube-sizing-close></button>
                </div>
                <iframe class="${this.styles.iframe}" src="https://bikesizing.cube.eu/iframe${this.cubeMode?'/cube':''}/${this.productId}/${this.productTitle}${this.language ? '?language=' + this.language : ''}${this.color ? (this.language ? '&' : '?') + 'color=' + encodeURI(this.color).replace(/#/g, '%23') : ''}"  data-cube-sizing-iframe width="100%"></iframe>
            </div>        
                    `;
        this.$modalBox.style.display = 'none';
        document.body.appendChild(this.$modalBox);
        this.$modalBox.addEventListener('click', (e) => {
            if (e.target.hasAttribute('data-cube-sizing-close') || e.target.hasAttribute('data-cube-sizing-wrapper')) {
                this.hideModalBox();
            }
        });
        this.resizeIframe();
    }

    /**
     * Anzeigen der Modalbox
     * @private
     */
    private showModalBox() {
        this.modalState = 'opened';
        this.$modalBox.style.display = '';
    }

    /**
     * Verstecken der Modalbox
     * @private
     */
    private hideModalBox() {
        this.modalState = 'closed';
        this.$modalBox.style.display = 'none';
    }

    /**
     * Styling definition
     * @private
     */
    private setupStyles() {
        jss.setup(preset())
        const {classes} = jss.createStyleSheet(mainStyles).attach();
        this.styles = classes;
    }
}

export {FrameCalculatorModal}
