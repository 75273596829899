const mainStyles = {
    modalBoxWrapper:{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        zIndex: 9999999,
        justifyContent: 'center',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            right: 0,
            background: '#000',
            opacity: 0.5,
            zIndex: -1
        }
    },
    modalBox: {
        all: 'initial',
        alignSelf: 'center',
        width: '90%',
        height: 'auto',
        padding: '1rem',
        background: '#fff',
        boxSizing: 'border-box',
        maxHeight: '100vh',
        overflow:'auto',
        //height: '70%',
        maxWidth: '80rem',
        //maxHeight: '60rem',
        //minHeight: '40rem',
        position: 'relative',
        boxShadow: {
            x: 0,
            y: 0,
            blur: '3rem',
            spread: '1rem',
            color: 'rgba(0,0,0,.175)',
            inset: null // If you want to add inset you need to write "inset: 'inset'"
        }
    },
    '@media (min-width: 1024px)': {
        modalBox: {
            width: '80vw',
        }
    },
    iframe: {
        all: 'initial',
        //height: '100%',
        width: '1px',
        minWidth: '100%',
        border: 'none'
    },
    closeIconWrapper: {
        all: 'initial',
        position: 'absolute',
        right: 0,
        top: 0
    },
    closeIcon: {
        all: 'initial !important',
        width: '2rem !important',
        margin: '0.5rem !important',
        height: '2rem !important',
        position: 'relative !important',
        cursor: 'pointer !important',
        '&:after': {
            top: '0 !important',
            content: '"" !important',
            height: '100% !important',
            borderLeft: '2px solid #000 !important',
            position: 'absolute !important',
            transform: 'rotate(45deg) !important',
            left: '50% !important',
            display: 'block !important'
        },
        '&:before': {
            top: '0 !important',
            content: '"" !important',
            height: '100% !important',
            borderLeft: '2px solid #000 !important',
            position: 'absolute !important',
            transform: 'rotate(-45deg) !important',
            left: '50% !important',
            display: 'block !important'
        }
    }
}
export {mainStyles}
